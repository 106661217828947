/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DatasetInfoDto } from '../models/DatasetInfoDto';
import type { DatasetItemDto } from '../models/DatasetItemDto';
import type { DatasetShortInfoDto } from '../models/DatasetShortInfoDto';
import type { ItemCountDto } from '../models/ItemCountDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DatasetService {
  /**
   * list datasets
   * List all available datasets (views and repos) with minimal info.
   * Datasets that are __not__ in a clean state (not completely created; partially deleted) are excluded
   * @param page
   * @param pageSize
   * @returns DatasetShortInfoDto get minimal info for all available repositories
   * @throws ApiError
   */
  public static datasetControllerListIds(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<DatasetShortInfoDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset',
      query: {
        page: page,
        pageSize: pageSize,
      },
      errors: {
        500: `unknown error`,
      },
    });
  }

  /**
   * get datasets info
   * Get detailed info about a dataset and it's dependencies
   * Note that references to or referencing datasets are __included__ in the *referencesTo* or *referencedBy*         arrays even if they are __not fully created__. This is because for datasets in creation we expect the new         reference to become factual in the future and thus a dataset even though it is not fully created already         blocks deletion of it's dependencies
   * @param datasetId
   * @returns DatasetInfoDto
   * @throws ApiError
   */
  public static datasetControllerGetInfo(
    datasetId: string,
  ): CancelablePromise<DatasetInfoDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}',
      path: {
        datasetId: datasetId,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * list items in the dataset
   * Get a list of items in a dataset
   * - string fields are returned as-is
   * - binary fields are resolved to the sha256 checksum of their content
   * - reference fields are resolved to the item id from the dataset they are referencing
   * @param datasetId
   * @param page
   * @param pageSize
   * @returns DatasetItemDto
   * @throws ApiError
   */
  public static datasetControllerListItems(
    datasetId: string,
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<DatasetItemDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/item',
      path: {
        datasetId: datasetId,
      },
      query: {
        page: page,
        pageSize: pageSize,
      },
      errors: {
        404: `dataset or item not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * get an item from the dataset
   * Receive the item from the dataset
   * Binary data fields contain the sha256 sum of their associated data. The data itself can be accessed through        the field get endpoint
   * @param datasetId
   * @param itemId
   * @returns DatasetItemDto
   * @throws ApiError
   */
  public static datasetControllerItem(
    datasetId: string,
    itemId: string,
  ): CancelablePromise<DatasetItemDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/item/{itemId}',
      path: {
        datasetId: datasetId,
        itemId: itemId,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * get data for a single field from an item
   * Download data form and item
   * For binary data the endpoint may return a redirect to where the data is hosted
   * @param datasetId
   * @param itemId
   * @param fieldName
   * @returns any
   * @throws ApiError
   */
  public static datasetControllerItemField(
    datasetId: string,
    itemId: string,
    fieldName: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/item/{itemId}/{fieldName}',
      path: {
        datasetId: datasetId,
        itemId: itemId,
        fieldName: fieldName,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * count items in the dataset
   * Get number of all committed items in the dataset
   * @param datasetId
   * @returns ItemCountDto
   * @throws ApiError
   */
  public static datasetControllerItemCount(
    datasetId: string,
  ): CancelablePromise<ItemCountDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/count',
      path: {
        datasetId: datasetId,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }
}
