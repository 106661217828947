import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { config } from './config';
import { AppRouter } from './AppRouter';
import { OpenAPI } from './api/generated';

// configure openApi object
OpenAPI.BASE = config.apiRoot;

ReactDOM.render(
  <React.StrictMode>
    <AppRouter basename={config.basename} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
