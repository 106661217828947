/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddViewDto } from '../models/AddViewDto';
import type { NamedResourceDto } from '../models/NamedResourceDto';
import type { ViewShortInfoDto } from '../models/ViewShortInfoDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ViewService {
  /**
   * list views
   * List all available and ready views with minimal info. Similar to *list repositories*
   *
   * @param page
   * @param pageSize
   * @returns ViewShortInfoDto get minimal info for all available views
   * @throws ApiError
   */
  public static viewControllerListIds(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<ViewShortInfoDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/view',
      query: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  /**
   * create view
   * Create a new view
   * error/retry semantics are identical to *create repository*
   * @param requestBody
   * @returns ViewShortInfoDto repository with a matching schema definition already exists, returns the existing repo
   * @throws ApiError
   */
  public static viewControllerAdd(
    requestBody: AddViewDto,
  ): CancelablePromise<ViewShortInfoDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/view',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        409: `repository with the same name exists but the schema differs to the one defined in this request, return the conflicting repo`,
        500: `unknown error`,
      },
    });
  }

  /**
   * delete view
   * Delete a view
   * error/retry semantics are identical to *delete repository*
   * @param datasetId
   * @returns NamedResourceDto deletion successful
   * @throws ApiError
   */
  public static viewControllerDelete(
    datasetId: string,
  ): CancelablePromise<NamedResourceDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/view/{datasetId}',
      path: {
        datasetId: datasetId,
      },
      errors: {
        404: `view not found`,
        500: `unknown error`,
      },
    });
  }
}
