import React from 'react';
import { ApiCallState } from '../api/common';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  ArrowLeft,
  Plus,
  Diagram3,
  Search,
} from 'react-bootstrap-icons';
import { OpenAPI, ViewService, ViewShortInfoDto } from '../api/generated';
import { useKeycloak } from '@react-keycloak/web';

export function ListViews() {
  const [page, setPage] = React.useState<number>(0);
  const [views, setViews] = React.useState<ApiCallState<ViewShortInfoDto[]>>(
    {},
  );
  const pagesize = 20;
  const { keycloak } = useKeycloak();
  OpenAPI.TOKEN = keycloak.token;

  // load views
  React.useEffect(() => {
    (async () => {
      return await ViewService.viewControllerListIds(page, pagesize);
    })()
      .then((result) => setViews({ result }))
      .catch((err) => setViews({ error: `${err}` }));
  }, [page]);

  return (
    <div>
      <h1>Views</h1>
      <Link className="btn btn-success" to="/addview">
        <Plus /> Add
      </Link>
      <br />
      {(views.result?.length || 0) === 0 ? (
        'You do not have any views yet.'
      ) : (
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>name</th>
                <th>base dataset</th>
                <th>created at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {views.result?.map((v) => (
                <tr key={v.name}>
                  <td>{v.name}</td>
                  <td>
                    <Link to={`/dataset/${v.base}`}>{v.base}</Link>
                  </td>
                  <td>{v.createdAt}</td>
                  <td>
                    <Link
                      className="btn btn-outline-primary"
                      to={`/dataset/${v.name}/item`}
                      title="items"
                    >
                      <Diagram3 />
                    </Link>
                    &nbsp;
                    <Link
                      className="btn btn-outline-info"
                      to={`/dataset/${v.name}`}
                      title="details"
                    >
                      <Search />
                    </Link>
                    &nbsp;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {views.result === undefined || pagesize <= views.result?.length ? (
            <div className="mt-3">
              <button
                className="btn btn-outline-secondary me-3"
                onClick={() => setPage(Math.max(0, page - 1))}
              >
                <ArrowLeft /> prev
              </button>{' '}
              {page}{' '}
              <button
                className="btn btn-outline-primary ms-3"
                onClick={() => setPage(page + 1)}
              >
                next <ArrowRight />
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}
