import React from 'react';
import { ApiCallState } from '../api/common';
import { useParams, Link } from 'react-router-dom';
import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import {
  DatasetInfoDto,
  DatasetItemDto,
  DatasetService,
  OpenAPI,
} from '../api/generated';
import { useKeycloak } from '@react-keycloak/web';

export function ListItems() {
  const { datasetId } = useParams();
  const [page, setPage] = React.useState<number>(0);
  const [dataset, setDataset] = React.useState<ApiCallState<DatasetInfoDto>>(
    {},
  );
  const [items, setItems] = React.useState<ApiCallState<DatasetItemDto[]>>({});
  const pagesize = 20;
  const { keycloak } = useKeycloak();
  OpenAPI.TOKEN = keycloak.token;

  // load dataset info
  React.useEffect(() => {
    if (datasetId) {
      (async () => {
        return await DatasetService.datasetControllerGetInfo(datasetId);
      })()
        .then((result) => setDataset({ result }))
        .catch((err) => setDataset({ error: `${err}` }));
    }
  }, [datasetId]);

  // load items
  React.useEffect(() => {
    if (datasetId) {
      (async () => {
        return await DatasetService.datasetControllerListItems(
          datasetId,
          page,
          pagesize,
        );
      })()
        .then((result) => setItems({ result }))
        .catch((err) => setItems({ error: `${err}` }));
    }
  }, [datasetId, page]);

  if (!dataset.result) return null;

  return (
    <div>
      <h1>{dataset.result.name}</h1>
      {items.result != undefined && items.result?.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>id</th>
              {dataset.result.schema.map((v) => (
                <th key={v.name}>{v.name}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.result?.map((v, idx) => (
              <tr key={v.id}>
                <td>{v.id}</td>
                {/* TODO: construct fields according to schema (e.g. reference: link to item; binary: link to item url, ...) */}
                {dataset.result?.schema.map((s) => (
                  <td key={s.name}>{String(v.data[s.name])}</td>
                ))}
                <td>
                  <Link to={`./${v.id}`}>show</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="alert alert-warning" role="alert">
          Dataset has no Items
        </div>
      )}
      <div>
        {items.result == undefined || pagesize <= items.result?.length ? (
          <div className="mt-3">
            <button
              className="btn btn-outline-secondary me-3"
              onClick={() => setPage(Math.max(0, page - 1))}
            >
              <ArrowLeft /> prev
            </button>{' '}
            {page}{' '}
            <button
              className="btn btn-outline-primary ms-3"
              onClick={() => setPage(page + 1)}
            >
              next <ArrowRight />
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
