import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { NoPage } from './NoPage/NoPage';
import { MainLayout } from './MainLayout/MainLayout';
import { ListDatasets } from './ListDatasets/ListDatasets';
import { ListRepos } from './ListRepos/ListRepos';
import { ListViews } from './ListViews/ListViews';
import { ListItems } from './ListItems/ListItems';
import { ShowItem } from './ShowItem/ShowItem';
import { AddRepo } from './AddRepo/AddRepo';
import { AddView } from './AddView/AddView';
import { AddItem } from './AddItem/AddItem';
import { ShowDataset } from './ShowDataset/ShowDataset';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { config } from './config/config';

export interface AppRouterProps {
  basename?: string;
}

export function AppRouter(props: AppRouterProps) {
  return (
    <ReactKeycloakProvider authClient={new Keycloak(config.keycloak)}>
      <BrowserRouter basename={props.basename || ''}>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Navigate to="dataset/" />} />

            <Route path="dataset" element={<ListDatasets />} />
            <Route path="dataset/:datasetId" element={<ShowDataset />} />
            <Route path="dataset/:datasetId/item" element={<ListItems />} />
            <Route
              path="dataset/:datasetId/item/:itemId"
              element={<ShowItem />}
            />

            <Route path="view" element={<ListViews />} />
            <Route path="repo" element={<ListRepos />} />
            <Route path="repo/:repoId/additem" element={<AddItem />} />

            <Route path="addrepo" element={<AddRepo />} />
            <Route path="addview" element={<AddView />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}
