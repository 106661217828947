import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

export function Navbar() {
  const { keycloak } = useKeycloak();
  return (
    <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/DSM-logo-680x610.png" alt="Bootstrap" width="45" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/dataset/">
                Datasets
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/repo/">
                Repos
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/view/">
                Views
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden xl:flex items-center space-x-5">
          <div className="hover:text-gray-200">
            {!keycloak.authenticated && (
              <button
                type="button"
                className="text-blue-800"
                onClick={() => keycloak.login()}
              >
                Login
              </button>
            )}

            {!!keycloak.authenticated && (
              <button
                type="button"
                className="text-blue-800"
                onClick={() => keycloak.logout()}
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
