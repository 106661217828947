/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddRepoDto } from '../models/AddRepoDto';
import type { DatasetItemDto } from '../models/DatasetItemDto';
import type { DatasetShortInfoDto } from '../models/DatasetShortInfoDto';
import type { InitItemInsertDto } from '../models/InitItemInsertDto';
import type { ItemCommitDto } from '../models/ItemCommitDto';
import type { NamedResourceDto } from '../models/NamedResourceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RepoService {
  /**
   * list repositories
   * List all available and ready repositories with minimal info.
   * Repos that are __not__ in a clean state (not completely created; partially deleted) are excluded
   * @param page
   * @param pageSize
   * @returns DatasetShortInfoDto get minimal info for all available repositories
   * @throws ApiError
   */
  public static repoControllerListIds(
    page?: number,
    pageSize?: number,
  ): CancelablePromise<Array<DatasetShortInfoDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/repo',
      query: {
        page: page,
        pageSize: pageSize,
      },
      errors: {
        500: `unknown error`,
      },
    });
  }

  /**
   * create repository
   * Create a new repository
   * This endpoint can be called multiple times with the same repo name, as long as the schema matches that of an         existing repo under this name. This allows for simple "create if not present" logic.
   * If the call fails, resources may be partially created. In that case the repo is marked as not ready and will         __not__ be available for item operations. This can either be resolved by retrying the create (this) call until         success or by deleting the (partially created) repo.
   * Note that a partially created repo still occupies it's name and thus creating a different dataset under the         same name will fail.
   * @param requestBody
   * @returns DatasetShortInfoDto repository with a matching schema definition already exists, returns the existing repo
   * @throws ApiError
   */
  public static repoControllerAdd(
    requestBody: AddRepoDto,
  ): CancelablePromise<DatasetShortInfoDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/repo',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `got incorrect data in request`,
        409: `repository with the same name exists but the schema differs to the one defined in this request, return the conflicting repo`,
        500: `unknown error`,
      },
    });
  }

  /**
   * delete repository
   * Delete a repository and free all associated resources
   * sematics are similar to *create repository*: if deletion fails we might end up with a partially deleted         repo. This endpoint can be called again to eventually finish the deletion process. This is especiall         important since cleaning up large amounts of storage might take some time and produce multiple timeouts         before all files have been removed.
   * In the meantime it is ensured that the repo can not be used while deleting (references are removed at the         the start of the deletion process and a repository that is in a deleting state is invisible to item         operations)
   * @param datasetId
   * @returns NamedResourceDto deletion successful
   * @throws ApiError
   */
  public static repoControllerDelete(
    datasetId: string,
  ): CancelablePromise<NamedResourceDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/repo/{datasetId}',
      path: {
        datasetId: datasetId,
      },
      errors: {
        404: `repo not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * init item insert
   * Initializes a new item insert operation.
   * Inserting an item requires the following steps:
   * 1. initialize insert to obtain a new dataset item id
   * 2. use the id to upload data for the new dataset item
   * 3. commit the new item
   *
   * The new item will only be available for reading once the commit was successful. The new item needs to       be comitted before it's *deadline* exceeds, otherwise the insert is considered unsuccessful and all data       associated with the item will get cleaned up.
   * @param datasetId
   * @returns InitItemInsertDto new insert transaction has been initialized
   * @throws ApiError
   */
  public static repoControllerInitItemInsert(
    datasetId: string,
  ): CancelablePromise<InitItemInsertDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/repo/{datasetId}/item',
      path: {
        datasetId: datasetId,
      },
      errors: {
        404: `repo not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * upload item fields
   * Use this to upload multiple fields of item data at once. This does not work in the swagger UI due to the dynamic nature of the api surface schema
   * @param datasetId
   * @param itemId
   * @param formData
   * @returns any upload successful
   * @throws ApiError
   */
  public static repoControllerUploadItem(
    datasetId: string,
    itemId: string,
    formData: Record<string, string | Blob>,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/repo/{datasetId}/item/{itemId}',
      path: {
        datasetId: datasetId,
        itemId: itemId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        404: `repo or item not found or not available for upload (insert not initialized or deadline exceeded)`,
        422: `data does not conform to schema`,
        500: `unknown error`,
      },
    });
  }

  /**
   * upload data for a single field
   * Use this to upload data for a single field in a datapoint
   * Note that the mime type in the request needs to be set according to the schema
   * @param datasetId
   * @param itemId
   * @param fieldName
   * @param requestBody
   * @returns any upload successful
   * @throws ApiError
   */
  public static repoControllerUploadItemField(
    datasetId: string,
    itemId: string,
    fieldName: string,
    requestBody: string | Blob,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/repo/{datasetId}/item/{itemId}/field/{fieldName}',
      path: {
        datasetId: datasetId,
        itemId: itemId,
        fieldName: fieldName,
      },
      body: requestBody,
      mediaType: 'text/plain',
      errors: {
        404: `repo or item not found (similar to upload) or field not in schema`,
        422: `data does not conform to schema`,
        500: `unknown error`,
      },
    });
  }

  /**
   * commit new item to the dataset
   * Validates all data that was uploaded or the item. Every field is checked for integrity against a hash value         provided in the query body.
   * If the item is complete and integrity is valid the item will be marked as committed and will be sealed         against alternation.
   * @param datasetId
   * @param itemId
   * @param requestBody
   * @returns DatasetItemDto item was already committed before, integrity is not checked again
   * @throws ApiError
   */
  public static repoControllerCommitItem(
    datasetId: string,
    itemId: string,
    requestBody: ItemCommitDto,
  ): CancelablePromise<DatasetItemDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/repo/{datasetId}/item/{itemId}/commit',
      path: {
        datasetId: datasetId,
        itemId: itemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `repo or item not found`,
        422: `data does not conform to schema`,
        500: `unknown error`,
      },
    });
  }
}
