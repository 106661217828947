import { Config } from './typing';

export const config: Config = {
  basename: '/',
  apiRoot: '',
  keycloak: {
    url: 'https://auth.optocycle.net',
    realm: 'optocycle',
    clientId: 'staging.dsm.office.optocycle.net',
  },
};
