import { useKeycloak } from '@react-keycloak/web';
import { ApplyYamlResource } from '../AddRepo/AddRepo';
import { ViewService } from '../api/generated/services/ViewService';
import { AddViewDto, OpenAPI } from '../api/generated';

export function AddView() {
  const { keycloak } = useKeycloak();
  OpenAPI.TOKEN = keycloak.token;
  return (
    <div>
      <h1>Add View</h1>
      <p>
        <i>Enter YAML view description</i>
      </p>
      <ApplyYamlResource
        apiCallback={(data: AddViewDto) => ViewService.viewControllerAdd(data)}
      />
    </div>
  );
}
