import React from 'react';
import { ApiCallState } from '../api/common';
import { useParams, Link } from 'react-router-dom';
import { config } from '../config';
import {
  DatasetService,
  DatasetStringFieldSchemaDto,
  DatasetReferenceFieldSchemaDto,
  DatasetBinaryFieldSchemaDto,
  DatasetInfoDto,
  DatasetItemDto,
  OpenAPI,
} from '../api/generated';
import { DatasetFieldSchema } from '../api/api-type-helper';
import { useKeycloak } from '@react-keycloak/web';

interface ItemFieldProps {
  schema: DatasetFieldSchema;
  value: string | number;
  datasetId: string;
  itemId: string;
}

function ItemField(props: ItemFieldProps) {
  switch (props.schema.type) {
    case 'string':
      return (
        <div>
          {props.schema.name}: {props.value}
        </div>
      );
    case 'reference':
      return (
        <div>
          {props.schema.name}:{' '}
          <Link
            to={`/dataset/${
              (props.schema as DatasetReferenceFieldSchemaDto).target
            }/item/${props.value}`}
          >
            {props.value}
          </Link>
        </div>
      );
    case 'binary':
      return (
        <div>
          {props.schema.name}:{' '}
          <a
            href={`${config.apiRoot}/api/dataset/${props.datasetId}/item/${props.itemId}/${props.schema.name}`}
          >
            data
          </a>
          <ul>
            <li>sha256 hash: {props.value}</li>
            <li>
              mime type: {(props.schema as DatasetBinaryFieldSchemaDto).mime}
            </li>
          </ul>
        </div>
      );
    default:
      return (
        <div>
          {(props.schema as DatasetFieldSchema).name
            ? (props.schema as DatasetFieldSchema).name
            : 'unknwon'}
          : NOT IMPLEMENTED
        </div>
      );
  }
}

export function ShowItem() {
  const { datasetId, itemId } = useParams();
  const [dataset, setDataset] = React.useState<ApiCallState<DatasetInfoDto>>(
    {},
  );
  const [item, setItem] = React.useState<ApiCallState<DatasetItemDto>>({});
  const { keycloak } = useKeycloak();
  OpenAPI.TOKEN = keycloak.token;

  // load dataset info
  React.useEffect(() => {
    if (datasetId) {
      (async () => {
        //return await apiGetDatasetInfo(datasetId);
        return await DatasetService.datasetControllerGetInfo(datasetId);
      })()
        .then((result) => setDataset({ result }))
        .catch((err) => setDataset({ error: `${err}` }));
    }
  }, [datasetId]);

  // load item
  React.useEffect(() => {
    if (datasetId && itemId) {
      (async () => {
        //return await apiGetDatasetItem(datasetId, parseInt(itemId));
        return await DatasetService.datasetControllerItem(datasetId, itemId);
      })()
        .then((result) => setItem({ result }))
        .catch((err) => setItem({ error: `${err}` }));
    }
  }, [datasetId]);

  if (!dataset.result || !item.result) return null;

  return (
    <div>
      {dataset.result.schema.map((s) => (
        <ItemField
          key={s.name}
          schema={s}
          value={(item.result as any)[s.name]} //TODO: use proper typeing
          datasetId={datasetId as string}
          itemId={itemId as string}
        ></ItemField>
      ))}
    </div>
  );
}
