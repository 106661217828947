import React from 'react';
import { ApiCallState } from '../api/common';
import { useParams, Link } from 'react-router-dom';
import stylesMessageBox from '../AddRepo/MessageBox.module.scss';
import {
  DatasetService,
  RepoService,
  ViewService,
  DatasetInfoDto,
  OpenAPI,
} from '../api/generated';
import { useKeycloak } from '@react-keycloak/web';

export function ShowDataset() {
  const { datasetId } = useParams();
  const [dataset, setDataset] = React.useState<ApiCallState<DatasetInfoDto>>(
    {},
  );
  const [apiCall, setApiCall] = React.useState<ApiCallState<Object | false>>(
    {},
  );
  const { keycloak } = useKeycloak();
  OpenAPI.TOKEN = keycloak.token;

  // load dataset info
  React.useEffect(() => {
    if (datasetId) {
      (async () => {
        return await DatasetService.datasetControllerGetInfo(datasetId);
      })()
        .then((result) => setDataset({ result }))
        .catch((err) => setDataset({ error: `${err}` }));
    }
  }, [datasetId]);

  function handleDelete(event: React.MouseEvent | React.TouchEvent) {
    event.preventDefault();
    (async () => {
      setApiCall({ result: false });
      if (dataset.result?.view) {
        return await ViewService.viewControllerDelete(datasetId as string);
      } else if (dataset.result?.repo) {
        return await RepoService.repoControllerDelete(datasetId as string);
      } else {
        throw new Error('dataset is neiter view nor repo: invalid');
      }

      return {};
    })()
      .then((result) => setApiCall({ result }))
      .catch((err) => setApiCall({ error: `${err['message']}` }));
  }

  if (!dataset.result) return null;

  return (
    <div>
      <h1>{dataset.result.name}</h1>
      {/* indicate busy state */}
      {'result' in apiCall && apiCall.result === false && (
        <div
          className={`${stylesMessageBox.message_box} ${stylesMessageBox.busy}`}
        >
          busy...
        </div>
      )}
      {/* display api success */}
      {!!apiCall.result && (
        <div
          className={`${stylesMessageBox.message_box} ${stylesMessageBox.success}`}
        >
          Request successful
        </div>
      )}
      {/* display api error message */}
      {!!apiCall.error && (
        <div
          className={`${stylesMessageBox.message_box} ${stylesMessageBox.error}`}
        >
          {apiCall.error}
        </div>
      )}

      <div>
        actions:{' '}
        <button className="btn btn-danger" onClick={handleDelete}>
          delete
        </button>
      </div>
      <div>
        creationTime: {dataset.result.createdAt}
        <br />
      </div>
      <div>
        schema:<pre>{JSON.stringify(dataset.result.schema, null, 2)}</pre>
      </div>
      {dataset.result.view ? (
        <div>
          view:<pre>{JSON.stringify(dataset.result.view, null, 2)}</pre>
        </div>
      ) : null}
      <div>
        referencesTo:
        <ul>
          {dataset.result.referencesTo.map((v, idx) => (
            <li key={idx}>
              <Link to={`/dataset/${v}`}>{v}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div>
        referencedBy:
        <ul>
          {dataset.result.referencedBy.map((v, idx) => (
            <li key={idx}>
              <Link to={`/dataset/${v}`}>{v}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
