import React from 'react';
import { ApiCallState } from '../api/common';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  ArrowLeft,
  Plus,
  Diagram3,
  Search,
} from 'react-bootstrap-icons';
import { RepoService, DatasetShortInfoDto, OpenAPI } from '../api/generated';
import { useKeycloak } from '@react-keycloak/web';

export function ListRepos() {
  const [page, setPage] = React.useState<number>(0);
  const [repos, setRepos] = React.useState<ApiCallState<DatasetShortInfoDto[]>>(
    {},
  );
  const { keycloak } = useKeycloak();
  OpenAPI.TOKEN = keycloak.token;

  const pagesize = 20;

  // load repos
  React.useEffect(() => {
    (async () => {
      return await RepoService.repoControllerListIds(page, pagesize);
    })()
      .then((result) => setRepos({ result }))
      .catch((err) => setRepos({ error: `${err}` }));
  }, [page]);

  return (
    <div>
      <h1>Repositories</h1>
      <Link className="btn btn-success" to="/addrepo">
        <Plus /> Add
      </Link>
      <br />
      {repos.result?.length == 0 ? (
        'You do not have any repositories yet.'
      ) : (
        <div className="mt-2">
          <table className="table">
            <thead>
              <tr>
                <th>name</th>
                <th>created at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {repos.result?.map((v) => (
                <tr key={v.name}>
                  <td>{v.name}</td>
                  <td>{v.createdAt}</td>
                  <td>
                    <Link
                      className="btn btn-outline-primary"
                      to={`/dataset/${v.name}/item`}
                      title="items"
                    >
                      <Diagram3 />
                    </Link>
                    &nbsp;
                    <Link
                      className="btn btn-outline-info"
                      to={`/dataset/${v.name}`}
                      title="details"
                    >
                      <Search />
                    </Link>
                    &nbsp;
                    <Link
                      className="btn btn-outline-success"
                      to={`/repo/${v.name}/additem`}
                      title="add item"
                    >
                      <Plus />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {repos.result == undefined || pagesize <= repos.result?.length ? (
            <div className="mt-2">
              <button
                className="btn btn-outline-secondary me-2"
                onClick={() => setPage(Math.max(0, page - 1))}
              >
                <ArrowLeft /> prev
              </button>{' '}
              {page}{' '}
              <button
                className="btn btn-outline-primary ms-2"
                onClick={() => setPage(page + 1)}
              >
                {' '}
                next <ArrowRight />
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}
