import React from 'react';
import { ApiCallState } from '../api/common';
import { DatasetService } from '../api/generated/services/DatasetService';
import { useParams, useNavigate } from 'react-router-dom';
import { SendPlus } from 'react-bootstrap-icons';
import {
  DatasetReferenceFieldSchemaDto,
  DatasetStringFieldSchemaDto,
  DatasetBinaryFieldSchemaDto,
  DatasetInfoDto,
  RepoService,
  OpenAPI,
} from '../api/generated';
import { DatasetFieldSchema } from '../api/api-type-helper';
import { useKeycloak } from '@react-keycloak/web';

function ItemFormField(props: DatasetFieldSchema) {
  switch (props.type) {
    case 'string':
      return (
        <ItemFormFieldString {...(props as DatasetStringFieldSchemaDto)} />
      );
    case 'reference':
      return (
        <ItemFormFieldReference
          {...(props as DatasetReferenceFieldSchemaDto)}
        />
      );
    case 'binary':
      return (
        <ItemFormFieldBinary {...(props as DatasetBinaryFieldSchemaDto)} />
      );
    default:
      return (
        <div>
          {(props as DatasetFieldSchema).name
            ? (props as DatasetFieldSchema).name
            : 'unknown'}
          : NOT IMPLEMENTED
        </div>
      );
  }
}

function ItemFormFieldString(props: DatasetStringFieldSchemaDto) {
  return (
    <div className="form-group row">
      <label htmlFor="schemaNameString" className="col-sm-2 col-form-label">
        {props.name}:{' '}
      </label>
      <div className="col-sm-10">
        <input
          type="text"
          className="form-control my-1"
          id="schemaNameString"
          name={props.name}
          maxLength={props.length}
          // NOTE: regex matching doesn't seem to work with modifiers? (e.g. "/^\\+?\\d+ \\d+$/i" always reports mismatch)
          // pattern={props.schema.regex}
        />
      </div>
    </div>
  );
}

function ItemFormFieldReference(props: DatasetReferenceFieldSchemaDto) {
  return (
    <div className="form-group row">
      <label htmlFor="schemaNameRef" className="col-sm-2 col-form-label">
        {props.name}:{' '}
      </label>
      <div className="col-sm-10">
        <input
          type="number"
          className="form-control my-1"
          id="schemaNameRef"
          name={props.name}
        />
      </div>
    </div>
  );
}

function ItemFormFieldBinary(props: DatasetBinaryFieldSchemaDto) {
  return (
    <div className="form-group row">
      <label htmlFor="schemaNameBin" className="col-sm-2 col-form-label">
        {props.name}:{' '}
      </label>
      <div className="col-sm-10">
        <input
          type="file"
          className="form-control-file my-1"
          id="schemaNameBin"
          name={props.name}
          accept={props.mime}
        />
      </div>
    </div>
  );
}

export function AddItem() {
  const { repoId } = useParams();
  const [dataset, setDataset] = React.useState<ApiCallState<DatasetInfoDto>>(
    {},
  );
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  OpenAPI.TOKEN = keycloak.token;

  // load dataset info
  React.useEffect(() => {
    if (repoId) {
      (async () => {
        return await DatasetService.datasetControllerGetInfo(repoId);
      })()
        .then((result) => setDataset({ result }))
        .catch((err) => setDataset({ error: `${err}` }));
    }
  }, [repoId]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    const data = new FormData(event.target as HTMLFormElement);

    (async () => {
      if (!repoId) throw new Error('repoId not set in url');
      const newItem = await RepoService.repoControllerInitItemInsert(repoId);
      let uploadData = Object.fromEntries(data.entries());
      await RepoService.repoControllerUploadItem(
        repoId,
        newItem.id,
        uploadData,
      );
      const integrity = Object.fromEntries(
        await Promise.all(
          Object.entries(uploadData).map(async ([key, value]) => [
            key,
            { sha256: await hashData(value) },
          ]),
        ),
      );
      return await RepoService.repoControllerCommitItem(repoId, newItem.id, {
        integrity,
      });
    })().then((item) => navigate(`/dataset/${repoId}/item/${item.id}`));
  }

  if (!dataset.result) return null;

  return (
    <div>
      <h1>{dataset.result.name}: Add Item</h1>
      <form onSubmit={handleSubmit}>
        {dataset.result.schema.map((v, idx) => (
          <ItemFormField key={idx} {...v} />
        ))}
        <button className="btn btn-success mt-3" type="submit">
          Submit <SendPlus />
        </button>
      </form>
    </div>
  );
}

async function hashData(data: string | Blob): Promise<String> {
  let buffer;
  if (typeof data == 'string') {
    buffer = new TextEncoder().encode(data); // Convert string to buffer
  } else {
    buffer = await data.arrayBuffer(); // Convert Blob to buffer
  }
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer); // Hash the buffer
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // Convert bytes to hex string
  return hashHex;
}
